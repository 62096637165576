import {Component, Vue} from 'vue-property-decorator';
import {getPlatformParams, resetGoodsSearch, setPlatformParams} from "@/api/$platform";
// import CanvasVideo from '@/components-new/canvas-video/canvas-video'

export default @Component({
  components: {
    // CanvasVideo,
  }
})
class ParamsManagerPage extends Vue {
  form = {
    paymentTimeLimitForIndividualPurchase: '00:30',
    settlementDateWithMerchant: 1,
    phone: ''
  };

  options = {
    step: '00:10',
    start: '00:30',
    end: '24:00'
  };

  mounted() {
    this.load();
  }

  updated() {
    this.checkSettlementDateWithMerchant();
  }

  load() {
    getPlatformParams().then(resp => {
      let {payhour, payminute, payday, phone} = resp;
      payhour = payhour || 0;
      payminute = payminute || 30;
      payminute -= payhour * 60
      payday = payday || 1;
      this.form.paymentTimeLimitForIndividualPurchase = `${payhour < 10 ? `0${payhour}` : payhour}:${payminute < 10 ? `0${payminute}` : payminute}`;
      this.form.settlementDateWithMerchant = payday;
      this.form.phone = phone;
    });
  }

  checkSettlementDateWithMerchant() {
    if (!this.form.settlementDateWithMerchant) {
      this.form.settlementDateWithMerchant = 1;
    }
  }

  submit() {
    if (!this.form.phone) {
      return this.$message.error('请填写平台客服电话！');
    }

    const [payhour, payminute] = this.form.paymentTimeLimitForIndividualPurchase.split(':');
    setPlatformParams({
      payhour: +payhour,
      payminute: +payminute + +payhour * 60,
      payday: this.form.settlementDateWithMerchant,
      phone: this.form.phone,
    }).then(resp => {
      this.$message.success('保存成功');
    });
  }

  resetGoodsSearch() {
    resetGoodsSearch().then(() => {
      this.$message.success('商品搜索缓存已重置');
    })
  }
}
