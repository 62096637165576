<template>
  <div class="page-params-manager">
    <el-card class="box-card">
      <div slot="header" class="row no-gutters align-items-center">
        <div class="col">
          <b>基本信息</b>
        </div>
        <div class="col-auto">
          <el-button type="primary" @click="submit">保存</el-button>
        </div>
      </div>
      <el-form ref="form" :model="form" label-width="160px">
        <el-form-item label="单独购买付款时限：">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <el-time-select
                placeholder="选择时限"
                v-model="form.paymentTimeLimitForIndividualPurchase"
                :default-value="form.paymentTimeLimitForIndividualPurchase"
                :picker-options="options"
              />
            </div>
            <div class="col pl-3">
              （最短30分钟，最长24小时，间隔10分钟）
            </div>
          </div>
        </el-form-item>
        <el-form-item label="与商户结算日期：">
          <div class="row no-gutters align-items-center">
            <div class="col-auto pr-3">
              T +
            </div>
            <div class="col-auto">
              <el-input-number v-model="form.settlementDateWithMerchant" :min="1" label="与商户结算日期"/>
            </div>
            <div class="col pl-3">
              个工作日进行结算
            </div>
          </div>
        </el-form-item>
        <el-form-item label="平台客服电话：">
          <el-input type="text" v-model="form.phone" placeholder="请填写平台客服电话" style="width: 300px" />
        </el-form-item>
        <el-form-item label="商品搜索缓存：">
          <el-button type="primary" @click="resetGoodsSearch">重置商品搜索缓存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
<!--    <canvas-video src="http://zhonghaifp.oss-cn-beijing.aliyuncs.com/video/article_video/8895a888-03d3-43f3-b40f-74e1c2011251.MOV"></canvas-video>-->
  </div>
</template>

<script lang="js">
import ParamsManagerPage from './params-manager';

export default ParamsManagerPage;
</script>

<style lang="scss">
@import "./params-manager";
</style>
